import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

Sentry.init({
    dsn: "https://30537d4122184e9b998fa1c4bfdb8a87@o281382.ingest.sentry.io/1369181",
    environment: process.env.REACT_APP_ENV
  });

const popUpByResponseStatus = (status) => {

  switch (status) {
    case 403: {
      MySwal.fire({
        text: "You are not authorized or your session has expired!",
        icon: 'warning',
      }).then(() => {
        localStorage.removeItem("user");
        const url = window.location.protocol + "//" + window.location.host + "/#/logout";
        window.location.replace(url);
      });
      break;
    }
  }
}

/** Fetch intercept start **/
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  let [resource, config] = args;
  const response = await originalFetch(resource, config);
  if (resource && resource.includes(window.location.host) && response && response.status && response.status !== 200) {
    popUpByResponseStatus(response.status);
  }

  return response;
};
/** Fetch intercept end **/

/** Axios intercept start **/
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error && error.response && error.response.config && error.response.config.url && error.response.config.url.includes(window.location.host) && error.response.status && error.response.status !== 200) {
    popUpByResponseStatus(error.response.status);
  }

  return Promise.reject(error);
});
/** Axios intercept end **/

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
