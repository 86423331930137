import moment from "moment";

import { getAPIUrl } from "./components/APIUrl";

export const adhocMaintenancePopup = () => {
    let url = getAPIUrl("/maintenance-pop-up-config/adhoc");

    return fetch(url)
        .then((res) => res.json())
        .then((res) => {
            if (res && res[0]) {
                let maintenanceConfig = res[0];

                return maintenanceConfig;
            } else {
                return null;
            }
        });
};

export const maintenanceSchduleCheck = () => {
    let url = getAPIUrl("/maintenance-pop-up-config");

    return fetch(url)
        .then((res) => res.json())
        .then((res) => {
            if (res && res[0]) {
                let maintenanceConfig = res[0];
                let maintenanceStart = moment();
                let maintenanceEnd = moment();

                maintenanceStart.set({
                    hour: maintenanceConfig.start_time.split(":")[0],
                    minute: maintenanceConfig.start_time.split(":")[1],
                    second: maintenanceConfig.start_time.split(":")[2],
                });

                maintenanceEnd.set({
                    hour: maintenanceConfig.end_time.split(":")[0],
                    minute: maintenanceConfig.end_time.split(":")[1],
                    second: maintenanceConfig.end_time.split(":")[2],
                });

                maintenanceConfig.maintenanceStart = maintenanceStart;
                maintenanceConfig.maintenanceEnd = maintenanceEnd;

                return maintenanceConfig;
            } else {
                return null;
            }
        });
};

export const areArraysEqual = (array1, array2) => {
  if (array1.length === array2.length) {
    return array1.every(element => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
};

export const areArrayHaveDifferentValue = (array1, array2) => {
  let existOrNot = [];
  for (let item of array2) {
    if (array1.includes(item)) {
      existOrNot = [...existOrNot, true];
    } else {
      existOrNot = [...existOrNot, false];
    }
  }

  return existOrNot.includes(false);
}

/**
 * Check is file extension valid.
 *
 * @fileName String along with extension.
 * instance fileName.png
 *
 * @types Array of file extension.
 */
export const isFileTypeValid = (fileName, types) => {
  let fileExtension = fileName.split('.').pop();
  fileExtension = fileExtension ? fileExtension.toLowerCase() : "";
  return types.some(el => el.toLowerCase() === fileExtension);
}

export const messageOnErrorUploadFile = (error) => {
  let titleErrMsg = "Error";
  let errMsg = "Upload file failed, please try again later!";
  if (error.response) {
    if (error.response.status === 303) {
      errMsg = "There are suspicious file(s) detected, please scan the file(s) with antivirus software before uploading. If you don't have antivirus in your device, you may transfer file(s) to another device with antivirus and try again.";
    } else {
      if (error.response.data && error.response.data.message) {
        errMsg = error.response.data.message;
      }
    }
  } else if (error.request) {
    errMsg = "There are suspicious file(s) detected, please scan the file(s) with antivirus software before uploading. If you don't have antivirus in your device, you may transfer file(s) to another device with antivirus and try again.";
    if (error.message) {
      titleErrMsg = error.message;
    }
  } else {
    if (error.message) {
      errMsg = error.message;
    }
  }

  return {
    titleErrMsg,
    errMsg
  }
}

export const reSetFileNameOnDownloadFile = (fileName, mimeExtension) => {
  let finalFileName = fileName;

  if (mimeExtension) {
    finalFileName = `${fileName}.${mimeExtension}`;

    let splitFileName = fileName.split(".");
    if (splitFileName[splitFileName.length - 1]) {
      if (
        splitFileName[splitFileName.length - 1].toLowerCase() === mimeExtension.toLowerCase() ||
        (splitFileName[splitFileName.length - 1].toLowerCase() === "jpg" && mimeExtension.toLowerCase() === "jpeg")
      ) {
        finalFileName = fileName;
      }
    }
  }

  return finalFileName
}

export const isReceiptAmountLengthValid = (receiptAmount) => {
  const intMaxLength = 11;
  const decMaxLength = 4;

  let intLength = 0;
  let decLength = 0;

  let isValidIntLength = true;
  let isValidDecLength = true;

  const splitValue = receiptAmount.split(".");

  if (splitValue && splitValue[0] !== undefined && splitValue[0] !== null && splitValue[0].toString().length > 0) {
    intLength = splitValue[0].toString().length;
  }

  if (splitValue && splitValue[1] !== undefined && splitValue[1] !== null && splitValue[1].toString().length > 0) {
    decLength = splitValue[1].toString().length;
  }

  if (intLength > intMaxLength) {
    isValidIntLength = false;
  }

  if (decLength > decMaxLength) {
    isValidDecLength = false;
  }

  return (isValidIntLength && isValidDecLength);
}

/**
 * Check is file extension valid.
 *
 * @fileName String along with extension.
 * instance fileName.png
 *
 */
export const reduceFileNameLength = (fileName) => {
  let newFileName = "";
  if (typeof fileName === 'string') {
    newFileName = fileName.trim();
    if (newFileName.length > 60) {
      let arrFileName = newFileName.split(".");
      if (arrFileName.length === 1) {
        newFileName = newFileName.slice(0, 60);
      } else if (arrFileName.length > 1) {
        const ext = arrFileName.pop();

        if (arrFileName.length === 1) {
          newFileName = arrFileName[0];
        } else if (arrFileName.length > 1) {
          newFileName = arrFileName.join(".");
        }

        if (newFileName.length > 60) {
          newFileName = newFileName.slice(0, 60);
        }

        if (ext && ext.length < 5) {
          newFileName = newFileName + "." + ext;
        }
      }
    }
  }

  return newFileName;
}

/**
 * Encode string into Base64.
 *
 * @str String.
 * @totalEncoded total encode process.
 *
 */
export const encodeStringToBase64 = (str, totalEncodeProcess = 1) => {
  let encodedString = str;

  if (typeof str === "string" && totalEncodeProcess > 0) {
    let processNo = 1;
    while (processNo <= totalEncodeProcess) {
      encodedString = Buffer.from(encodedString).toString('base64');
      processNo++;
    }
  }

  return encodedString;
}
