export function getAPIUrl(action) {
    let url = "";

    if (process.env.REACT_APP_ENV === "localhost") {
        url = process.env.REACT_APP_API_LOCALHOST + action;
    } else if (process.env.REACT_APP_ENV === "development") {
        url = process.env.REACT_APP_API_DEVELOPMENT + action;
    } else if (process.env.REACT_APP_ENV === "production") {
        url = process.env.REACT_APP_API_PRODUCTION + action;
    } else if (process.env.REACT_APP_ENV === "staging") {
        url = process.env.REACT_APP_API_STAGING + action;
    }
    return url;
}

export function getReCAPCHASitekey() {
    let sitekey = "";

    if (process.env.REACT_APP_ENV === "localhost") {
        sitekey = process.env.REACT_APP_RECAPCHA_SITEKEY_LOCALHOST;
    } else if (process.env.REACT_APP_ENV === "development") {
        sitekey = process.env.REACT_APP_RECAPCHA_SITEKEY_DEVELOMENT;
    } else if (process.env.REACT_APP_ENV === "production") {
        sitekey = process.env.REACT_APP_RECAPCHA_SITEKEY_PRODUCTION;
    } else if (process.env.REACT_APP_ENV === "staging") {
        sitekey = process.env.REACT_APP_RECAPCHA_SITEKEY_DEVELOMENT;
    }
    return sitekey;
}


export function isRecaptchaEnabled() {
  let isEnabled;

  switch (process.env.REACT_APP_ENV) {
    case "localhost":
      isEnabled = process.env.REACT_APP_IS_ENABLE_RECAPTCHA_LOCALHOST;
      break;
    case "development":
      isEnabled = process.env.REACT_APP_IS_ENABLE_RECAPTCHA_DEVELOPMENT;
      break;
    case "production":
      isEnabled = process.env.REACT_APP_IS_ENABLE_RECAPTCHA_STAGING;
      break;
    case "staging":
      isEnabled = process.env.REACT_APP_IS_ENABLE_RECAPTCHA_PRODUCTION;
      break;
    default:
      isEnabled = true;
  }

  if (!isEnabled || isEnabled === "0" || isEnabled.toLowerCase() === "false") {
    return false;
  }

  return true;
}
