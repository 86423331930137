import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import axios from "axios";
import { getAPIUrl } from "./components/APIUrl";
import lodash from "lodash";
import moment from "moment-timezone";
import { maintenanceSchduleCheck } from "./utils";

moment.tz.setDefault('Etc/UTC');

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
    loader: () => import("./containers/DefaultLayout"),
    loading,
});

// Pages
const Login = Loadable({
    loader: () => import("./views/Pages/Login"),
    loading,
});

const Logout = Loadable({
    loader: () => import("./views/Pages/Logout"),
    loading,
});

const Disclaimer = Loadable({
    loader: () => import("./views/Pages/Disclaimer"),
    loading,
});

const Verify = Loadable({
    loader: () => import("./views/Pages/ViewClaim/Verify"),
    loading,
});

const ViewClaim = Loadable({
    loader: () => import("./views/Pages/ViewClaim/ViewClaim"),
    loading,
});

const Claim = Loadable({
    loader: () => import("./views/Pages/Claim"),
    loading,
});

const FollowUp = Loadable({
    loader: () => import("./views/Pages/FollowUp"),
    loading,
});

const ResetPassword = Loadable({
    loader: () => import("./views/Pages/ResetPassword"),
    loading,
});

const CreateAccount = Loadable({
    loader: () => import("./views/Pages/CreateAccount"),
    loading,
});

const Page404 = Loadable({
    loader: () => import("./views/Pages/Page404"),
    loading,
});

const Page500 = Loadable({
    loader: () => import("./views/Pages/Page500"),
    loading,
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMaintenance: false,
            isAuth: false,
            isEditCase: false,
            isEditClaim: false,
            admin: [],
            claimData: [],
            school: {},
        };

        this.editCase = this.editCase.bind(this);
        this.editClaim = this.editClaim.bind(this);
        this.initAdmin = this.initAdmin.bind(this);
        this.initSchool = this.initSchool.bind(this);
        this.initEditNewClaim = this.initEditNewClaim.bind(this);
        this.logoutAdmin = this.logoutAdmin.bind(this);
    }

    editClaim(input) {
        this.setState({
            isEditClaim: input,
        });
    }

    editCase(input) {
        this.setState({
            isEditCase: input,
        });
    }

    initAdmin(user) {
        this.setState({
            isAuth: true,
            admin: JSON.stringify(user),
        });
    }

    initEditNewClaim(claim) {
        this.setState({ claimData: claim });
    }

    initSchool(school) {
        this.setState({ school: school });
    }

    logoutAdmin() {
        this.setState({
            isAuth: false,
            admin: null,
        });
    }

    async componentDidMount() {
        // localStorage.removeItem("user");
        // const user = localStorage.getItem("user");

        // if (user) {
        //   this.setState({
        //     admin: JSON.stringify(user),
        //     isAuth: true
        //   });
        // }

        const maintenanceConfig = await maintenanceSchduleCheck();

        if (maintenanceConfig && moment().isBetween(maintenanceConfig.maintenanceStart, maintenanceConfig.maintenanceEnd)) {
            this.setState({ isMaintenance: true });
        }

        let url = getAPIUrl("/getLoggedin");

        // fetch(url, {
        //   method: "GET",
        //   headers: {
        //     "Cache-Control": "no-store"
        //   }
        // })
        //   .then(res => res.json())
        //   .then(res => {
        //     let user = res.data.user;

        //     if (!lodash.isUndefined(user) && user.id) {
        //       this.setState({
        //         isAuth: true,
        //         admin: JSON.stringify(user)
        //       });
        //     }
        //   });

        let self = this;
        axios.post(url, { method: "post", credentials: "same-origin" }).then(function (response) {
            axios.post(url, { method: "post" }).then(function (response) {
                // handle success
                var user = response.data.user;
                // console.log("getLoggedin -> ", user);

                if (!lodash.isUndefined(user) && user.id) {
                    self.setState({
                        isAuth: true,
                        admin: JSON.stringify(user),
                    });
                }
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <HashRouter>
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            name="Login Page"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/dashboard"
                            name="Dashboard"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/dashboard_2018"
                            name="Dashboard (2018)"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/dashboard_2019"
                            name="Dashboard (2019)"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/dashboard_2020"
                            name="Dashboard (2020)"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/dashboard_archive"
                            name="Dashboard Archive"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route path="/reset-password/:token/verify" component={ResetPassword} />
                        <Route path="/create-account/:token/verify" component={CreateAccount} />

                        <Route
                            exact
                            path="/view-claim/verify"
                            name="Verify"
                            render={(props) =>
                                !this.state.isMaintenance ? (
                                    <Verify initEditNewClaim={this.initEditNewClaim} {...props} />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/disclaimer"
                            name="Disclaimer"
                            render={(props) =>
                                !this.state.isMaintenance ? (
                                    <Disclaimer
                                        editCase={this.editCase}
                                        editClaim={this.editClaim}
                                        initEditNewClaim={this.initEditNewClaim}
                                        isLogged={this.state.isAuth}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/claim"
                            name="Claim"
                            render={(props) =>
                                !this.state.isMaintenance ? (
                                    <Claim
                                        admin={this.state.admin}
                                        claimData={this.state.claimData}
                                        isEditCase={this.state.isEditCase}
                                        isEditClaim={this.state.isEditClaim}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                          exact
                          path="/new-claim"
                          name="New Claim"
                          render={(props) =>
                            !this.state.isMaintenance ? (
                              <Claim
                                admin={this.state.admin}
                                claimData={this.state.claimData}
                                isEditCase={this.state.isEditCase}
                                isEditClaim={this.state.isEditClaim}
                                initEditNewClaim={this.initEditNewClaim}
                                {...props}
                              />
                            ) : (
                              <Login initAdmin={this.initAdmin} {...props} />
                            )
                          }
                        />

                        <Route
                            exact
                            path="/follow-up-claim"
                            name="Follow Up Claim"
                            render={(props) =>
                                !this.state.isMaintenance ? (
                                    <FollowUp
                                        admin={this.state.admin}
                                        claimData={this.state.claimData}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/view-claim"
                            name="ViewClaim"
                            render={(props) =>
                                !this.state.isMaintenance ? (
                                    <ViewClaim
                                        admin={this.state.admin}
                                        claimData={this.state.claimData}
                                        initEditNewClaim={this.initEditNewClaim}
                                        editCase={this.editCase}
                                        editClaim={this.editClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route exact path="/404" name="Page 404" component={Page404} />
                        <Route exact path="/500" name="Page 500" component={Page500} />
                        <Route
                            exact
                            path="/logout"
                            name="Logout"
                            render={(props) => <Logout admin={this.state.admin} logoutAdmin={this.logoutAdmin} {...props} />}
                        />

                        <Route
                            path="/admin"
                            name="Admin"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />

                        <Route
                            exact
                            path="/"
                            name="Home"
                            render={(props) =>
                                this.state.isAuth && !this.state.isMaintenance ? (
                                    <DefaultLayout
                                        admin={this.state.admin}
                                        school={this.state.school}
                                        claimData={this.state.claimData}
                                        initAdmin={this.initAdmin}
                                        initSchool={this.initSchool}
                                        initEditNewClaim={this.initEditNewClaim}
                                        {...props}
                                    />
                                ) : (
                                    <Login initAdmin={this.initAdmin} {...props} />
                                )
                            }
                        />
                        <Route component={Page500} />
                    </Switch>
                </HashRouter>
            </React.Fragment>
        );
    }
}

export default App;
